//
@media only screen and (max-width: 860px) {
  .body--no-scroll {
    overflow: hidden;
    width: 100%;
  }
}

.logo-cumplo {
  display: block;
  float: left;
  margin-right: 15px;
  margin-top: 7px;
  width: 220px;

  img {
    margin-top: 8px;
    max-width: 100%;
    max-height: 47px;
  }
}

.no-padding {
  padding: 0;
}

@media (min-width: 860px) {
  .logo-cumplo {
    margin-top: 11px;
    overflow: hidden;
    width: 90px;

    img {
      width: 165px;
      max-width: 165px;
    }
  }
}
@media only screen and (max-width: 860px) {
  .logo-cumplo {
    margin-top: 0;
    width: 180px;
  }
}

//Header
.cumplo-header {
  width: 100%;
  z-index: 11;
  background: #00ba68;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.1);
}

.cumplo-header .black-box {
  width: 100%;
  background: #3b454e;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media only screen and (max-width: 860px) {
  .cumplo-header .black-box {
    display: none;
  }
}

.cumplo-header .form-mod {
  display: none;
}

//Menu usuario
.header__actions__user {
  background: #3b454e;
  float: right;
  padding: 0 15px;
  padding-bottom: 0;
  position: relative;
  min-width: 273px;
  transition: all .3s ease;
}

@media only screen and (max-width: 1199px) {
  .header__actions__user {
    min-width: 213px;
  }
}

.header__actions__user a {
  color: #fff;
  font-size: 1.1em;
  display: block;
  font-weight: 100;
  position: relative;
  text-decoration: none;
  margin: 0;
  background: #3b454e;
  padding: 15px;
}

.header__actions__user > a {
  line-height: normal;
  padding: 0;
  color: #fff;
  background: initial;
  height: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 67px;
  -webkit-text-stroke-width: 0.6px;
  -webkit-font-smoothing: antialiased;
}

.header__actions__user > a:hover {
  background: initial;
}

.header__actions__user .image-user {
  margin-left: 10px;
  float: right;
  position: relative;
  height: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.header__actions__user .image-user .pro-tag {
  text-align: center;
  color: #000;
  font-size: 1em;
  font-weight: 500;
  padding: 1px 3px;
  border-radius: 0 0 3px 3px;
  position: absolute;
  top: 0;
  left: 2px;
}

.header__actions__user .arrow-down {
  margin-left: 5px;
}

@media only screen and (max-width: 860px) {
  .header__actions__user a {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 10px 0;
    padding-right: 10px;
    width: 100%;
  }

  .header__actions__user .arrow-down {
    font-size: 40px;
    margin-left: 20px;
  }
}

.header__actions__user__sub-menu {
  position: absolute;
  left: 0;
  width: 100%;
  color: #ffffff;
  margin-top: 10px;
  visibility: hidden;
  opacity: 0;
  text-align: right;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  z-index: 10;
  border-radius: 0 0 3px 3px;
  overflow: hidden;
}

.header__actions__user__sub-menu li {
  line-height: 100%;
  padding: 0;
  margin: 0;
  padding: 0 10px;
  text-align: left;
  width: 100%;
  border-top: 1px solid #5E6E79;
}

.header__actions__user__sub-menu li:first-child {
  border-top: 0;
}

.header__actions__user__sub-menu li a {
  color: #ffffff;
  line-height: 100%;
  margin: 0;
  display: block;
  font-size: 13px;
  width: 100%;
  background: #3b454e;
  text-align: left;
  padding: 18px 0 17px;
  padding-left: 30px;
  -webkit-text-stroke-width: 0.6px;
  -webkit-font-smoothing: antialiased;
}

.header__actions__user__sub-menu li a:before {
  content: '';
  width: 0;
  height: 100%;
  position: absolute;
  background: #00ba68;
  top: 0;
  left: 0;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}

.header__actions__user__sub-menu li a:hover:before, 
.header__actions__user__sub-menu li a.active:before {
  width: 7px;
}

@media only screen and (min-width: 860px) {
  .header__actions__user:hover .header__actions__user__sub-menu {
    visibility: visible;
    opacity: 1;
    margin: 0;
  }
}

.user-badge {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-size: .9em;
  width: 100%;
  height: 100%;
}

.user-badge .datos_user {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.user-badge .datos_user span {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 190px;
  text-align: left;
}

.user-badge .datos_user span.nombre-user strong {
  color: #B8E986;
  font-weight: 400;
}

.user-badge .datos_user span.mail-user {
  font-size: .9em;
  margin-top: 3px;
}

.user-badge i.fa {
  display: none;
}

@media only screen and (max-width: 860px) {
  .header__actions__user__sub-menu {
    visibility: hidden;
    margin-top: -5px;
    opacity: 0;
    border-radius: 0;
  }

  .header__actions__user__sub-menu.open-menu {
    visibility: visible;
    opacity: 1;
    margin-top: 1px;
  }

  .header__actions__user__sub-menu li a {
    font-size: 1.14em;
    padding: 30px 5px;
    padding-left: 30px;
    font-weight: normal;
    background: #252b31;
  }

  .user-badge i.fa {
    float: right;
    font-size: 2.2em;
    font-weight: bold;
    height: 100%;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 20px;
  }

  .user-badge .datos_user {
    font-size: 1.14em;
  }

  .user-badge .datos_user span {
    max-width: 90%;
  }
}

.submenu-nav {
  background: #3b454e;
  position: absolute;
  width: 198px;
  left: 0;
  border-radius: 0 0 3px 3px;
  overflow: hidden;
  margin-top: 10px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  opacity: 0;
  visibility: hidden;
  -webkit-text-stroke-width: 0.6px;
  -webkit-font-smoothing: antialiased;
}

.submenu-nav li {
  border-top: 1px solid #5E6E79;
  margin-bottom: 0;
}

.submenu-nav li:before {
  display: none;
}

.submenu-nav li:first-child {
  border: none;
}

.submenu-nav a {
  color: #ffffff;
  font-size: .8em;
  padding: 18px;
  padding-left: 30px;
  display: block;
  font-weight: 100;
  position: relative;
  text-decoration: none;
}

.submenu-nav a:before {
  content: '';
  width: 0;
  height: 100%;
  position: absolute;
  background: #00ba68;
  top: 0;
  left: 0;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}

.submenu-nav a:hover:before, .submenu-nav a.active:before {
  width: 7px;
}

.submenu-nav a i {
  font-size: 12px;
  margin-left: 3px;
  opacity: .5;
}

.header__top {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.header__top nav ul {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 100%;
}

.header__top .submenu-nav ul {
  margin: 0;
  display: block;
}

.header__top .sec-nav {
  position: relative;
  margin-bottom: 0;
}

.header__top .sec-nav:before {
  display: none;
}

.header__top .sec-nav:hover > a:before {
  content: '';
  height: 5px;
  width: 100%;
  background: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 1;
  transition: all .3s ease;
}

.header__top .sec-nav:hover .submenu-nav {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
}

.header__top .sec-nav.submenu {
  border-radius: 3px 3px 0 0;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}

.header__top .sec-nav.submenu > a:after {
  font-family: 'FontAwesome';
  content: "\f107";
  margin-left: 5px;
}

@media only screen and (max-width: 860px) {
  .header__top .sec-nav.submenu > a:after {
    display: none;
  }
}

.header__top .sec-nav.submenu:hover {
  background: #3b454e;
}

.header__top .sec-nav.submenu:hover > a:before {
  opacity: 0;
}

.header__top .sec-nav.submenu:hover > a:after {
  content: "\f106";
}

.header__top .sec-nav > a {
  cursor: pointer;
  text-decoration: none;
  -webkit-transition-duration: .2s;
  transition-duration: .2s;
  -webkit-transition-property: background;
  transition-property: background;
  margin: 0 10px;
  color: #fff;
  font-size: .8em;
  padding: 25px 5px;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  line-height: 1em;
  position: relative;
  font-weight: 400;
}

@media only screen and (max-width: 1110px) {
  .header__top .sec-nav > a {
    margin: 0 5px;
    padding: 15px 5px;
    height: 65px;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}

.header__top .sec-nav > a.no-link {
  cursor: default;
}

.header__top .sec-nav > a:before {
  content: '';
  opacity: 0;
}

.header__top .sec-nav > a.active:before {
  content: '';
  height: 5px;
  width: 100%;
  background: #ffffff;
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 1;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}

.header__support {
  -webkit-align-items: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center;
  display: none;
  margin-left: auto;
}

.header__support .button {
  -webkit-align-items: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center;
  margin-right: 10px;
  font-size: 13px;
  letter-spacing: 1px;
  border-color: #ffffff;
  color: #ffffff;
}

.header__support .button i {
  font-size: 1.5em;
  margin-right: 5px;
}

.header__support .button:last-child {
  margin-right: 0;
}

.header__nav {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
}

.header__nav nav ul,
.header__nav .header__actions ul {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: 0;
}

.header__nav nav ul li:first-child a,
.header__nav .header__actions ul li:first-child a {
  margin-left: 0;
}

.header__actions {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background: #3b454e;
}

.header__actions:before {
  content: '';
  position: absolute;
  background: #3b454e;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 860px) {
  .header__actions:before {
    display: none;
  }
}

.header__actions a {
  margin: 0 30px;
  color: #ffffff;
  font-size: .8em;
  font-weight: 400;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  text-decoration: none;
  position: relative;
  height: 100%;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  transition: color .3s ease;
}

@media only screen and (max-width: 1199px) {
  .header__actions a {
    margin: 0 10px;
  }
}

.header__actions a img {
  margin-right: 5px;
}

.header__actions a:hover, .header__actions a.active {
  color: #B8E986;
}

.header__actions a:last-child {
  margin-right: 0;
}

.mobile__nav-toggle {
  display: none;
}

.nav--main {
  margin: 5px 0 0 0;
}

@media only screen and (max-width: 1199px) {
  .nav--main {
    margin: 5px 10px 0 auto;
  }
}

@media only screen and (max-width: 860px) {
  .cumplo-header {
    padding-bottom: 0;
    position: fixed;
    top: 0;
    z-index: 100;
  }

  .cumplo-header.active {
    height: 100%;
    z-index: 15;
    -webkit-overflow-scrolling: touch;
  }

  .cumplo-header.active > .container {
    position: absolute;
    height: 100%;
    padding: 0;
    -webkit-overflow-scrolling: touch;
  }

  .cumplo-header .container {
    width: 100%;
    padding: 0;
  }

  .cumplo-header .form-mod {
    color: #ffffff;
    margin: 0;
    display: block;
    text-align: left;
    border-top: 1px solid #9B9B9B;
    height: initial;
    font-size: 1.14em;
    padding: 30px 15px 30px 25px;
    text-decoration: none;
    background: #252b31;
  }

  .cumplo-header .form-mod.logged {
    display: none;
  }

  .mobile__nav-toggle {
    display: block;
    float: right;
    padding: 4px;
    transition: all .3s ease;
  }

  .mobile__nav-toggle span {
    background: #ffffff;
    height: 5px;
    width: 35px;
    display: block;
    margin: 5px 0;
    transition: all .3s ease;
  }

  .mobile__nav-toggle:before, .mobile__nav-toggle:after {
    content: '';
    background: #ffffff;
    height: 5px;
    width: 35px;
    display: block;
    transition: all .3s ease;
    transform-origin: left top;
  }

  .mobile__nav-toggle.open {
    margin-right: -10px;
  }

  .mobile__nav-toggle.open span {
    width: 0;
  }

  .mobile__nav-toggle.open:before {
    transform: rotate(45deg);
  }

  .mobile__nav-toggle.open:after {
    transform: rotate(-45deg) translate(-6px);
  }

  .cont-sup {
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    overflow: hidden;
    padding: 14px 0;
    width: 96%;
    margin: 0 auto;
  }

  .cont-down {
    max-height: 0;
    overflow: hidden;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
  }

  .cont-down.open {
    height: calc(100vh - 61px);
    max-height: calc(100vh - 61px);
    overflow-y: auto;
    padding-bottom: 20px;
    background: #3b454e;
    padding: 0;
    -webkit-overflow-scrolling: touch;
  }

  .header__top {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0;
    padding-top: 10px;
  }

  .header__top .sec-nav {
    font-size: 1em;
  }

  .header__top .sec-nav:hover > a:before {
    content: '';
    width: 7px;
    height: 100%;
    position: absolute;
    background: #ffffff;
    top: 0;
    left: 0;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
  }

  .header__top .sec-nav > a {
    color: #ffffff;
    margin: 0;
    display: block;
    text-align: left;
    border-top: 1px solid #9B9B9B;
    height: initial;
    font-size: 1em;
    padding: 30px 15px 30px 25px;
  }

  .header__top .sec-nav > a.active:before {
    content: '';
    width: 7px;
    height: 100%;
    position: absolute;
    background: #ffffff;
    top: 0;
    left: 0;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
  }

  .header__top .sec-nav .submenu-nav {
    opacity: 1;
    visibility: visible;
    margin: 0;
    width: inherit;
    background: none;
    border-radius: 0;
  }

  .header__top .sec-nav .submenu-nav a {
    color: #C3C4CA;
    border-top: 1px solid #9B9B9B;
    font-weight: normal;
    font-size: 1.14em;
    padding: 30px 5px;
    padding-left: 50px;
  }

  .header__top .sec-nav .submenu-nav a:hover {
    background: none;
  }

  .header__top .sec-nav .submenu-nav li,
  .header__top .sec-nav .submenu-nav li:first-child {
    border: none;
  }

  .header__top .sec-nav.submenu:hover > a:after {
    content: "\f107";
  }

  .header__top .sec-nav.submenu:hover > a:before {
    opacity: 1;
  }

  .header__top nav ul {
    display: block;
  }

  .header__top{
    padding-bottom: 0;
  }

  .header__top {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column-reverse;
    flex-direction: column-reverse;
    padding: 0;
  }

  .header__top .nav--main {
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    overflow-y: auto;
    margin: 0 0 44px 0;
    width: 100%;
  }

  .header__support .button {
    width: 100%;
    margin-bottom: 10px;
  }

  .header__actions {
    margin: 0;
    text-align: center;
    -webkit-justify-content: center;
    justify-content: center;
    background: none;
    padding: 0 3%;
  }

  .header__actions a {
    margin: 0 5px;
    text-align: center;
    font-size: .9em;
    padding: 1.4em 0 1.2em;
    -webkit-flex: 1;
    flex: 1;
  }

  .header__actions a:last-child {
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
  }

  .submenu-nav,
  .submenu-nav.active {
    background: #e6e6e6;
    display: block;
    position: relative;
  }

  .submenu-nav a,
  .submenu-nav.active a {
    color: #3b454e;
    padding: 15px 35px;
    font-size: 1em;
    display: block;
    border-top: 1px solid rgba(0, 0, 0, 0.07);
  }

  .submenu-nav a.active,
  .submenu-nav.active a.active {
    font-weight: 700;
  }

  .submenu-nav a:hover,
  .submenu-nav.active a:hover {
    background: #e6e6e6;
    color: #3b454e;
  }

  .header__top nav a {
    margin: 0;
    display: block;
  }

  .header__actions__user {
    float: none;
    padding: 0;
    margin: 0;
    padding: 0 3%;
  }

  .header__actions__user > a {
    padding: 0;
    height: 65px;
  }

  .header__actions__user:hover {
    background: none;
  }
}

//Footer
.footer {
  background: white;
  position: relative;
  z-index: 10;
}

.footer.logged .logos__right {
  margin-right: 50px;
}
@media only screen and (max-width: 860px) {

  .footer.logged .logos__right {
    margin-right: 0;
  }
}

.footer.logged .upsite {
  bottom: 90px;
}

@media only screen and (max-width: 860px) {
  .footer {
    padding-bottom: 40px;
  }
}

.footer ul.dot {
  list-style-type: disc;
}

.footer .logos {
  padding: 30px 0;
}

.footer .logos .container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.footer .logos .container:before {
  display: none;
}

.footer .logos__row {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 30px;
}

.footer .logos__row:last-child {
  margin-bottom: 0;
}
@media only screen and (max-width: 860px) {

  .footer .logos__row {
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    justify-content: center;
    margin-bottom: 0;
  }
}

.footer .logos__right {
  -webkit-align-items: flex-end;
  align-items: flex-end;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
}
@media only screen and (max-width: 860px) {

  .footer .logos__right {
    -webkit-align-items: center;
    align-items: center;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.footer .logos__item {
  margin-right: 40px;
}

.footer .logo--footer {
  max-width: 340px;

  @media only screen and (max-width: 860px) {
    max-width: 100%;
  }
}
  
.footer .logos__item:last-child {
  margin-right: 0;
}

.footer .logos__item p {
  font-size: .6em;
  margin-bottom: 10px;
}

@media only screen and (max-width: 1050px) {

  .footer .logos__item img {
    max-width: 100px;
  }
}

.footer .logos__left {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}
@media only screen and (max-width: 860px) {

  .footer .logos__left {
    margin-bottom: 20px;
  }
}

.footer .logo--.footer {
  max-width: 398px;
  max-height: 59px;
}
@media only screen and (max-width: 1200px) {

  .footer .logo--.footer {
    max-width: 280px;
  }
}
@media only screen and (max-width: 860px) {

  .footer .logo--.footer {
    margin: 0 auto;
  }
}

.footer .cont-news-network {
  background: #ECEFF3;
  padding: 40px 0;
}

.footer .cont-news-network h3 {
  font-size: 2em;
  color: #00ba68;
  margin: 15px 0;
}

.footer .cont-news-network h4 {
  font-size: 1.6em;
  font-weight: 300;
  text-transform: none;
  margin: 15px 0;
}

.footer .cont-news-network p, .footer .cont-news-network a {
  font-size: 1em;
}

.footer .cont-news-network p.date, .footer .cont-news-network a.date {
  font-size: .8em;
}

.footer .cont-news-network .container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

@media only screen and (max-width: 860px) {
  .footer .cont-news-network .container {
    -webkit-flex-direction: column;
    flex-direction: column;
  }
}

.footer .cont-news-network .news {
  -webkit-flex: 1;
  flex: 1;
  padding-right: 50px;
  border-right: 1px solid #9B9B9B;
}
@media only screen and (max-width: 860px) {

  .footer .cont-news-network .news {
    padding: 0;
    border: 0;
  }
}

.footer .cont-news-network .news .links {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.footer .cont-news-network .news .info {
  font-weight: 100;
  line-height: 26px;
  -webkit-text-stroke-width: 0.6px;
  -webkit-font-smoothing: antialiased;
}

.footer .cont-news-network .news .links a {
  max-width: 242px;
  display: -webkit-flex;
  display: flex;
  text-decoration: none;
  background: #00ba68;
  border-radius: 3px;
  text-align: center;
  padding: 7px;
  color: #ffffff;
  -webkit-flex: 1;
  flex: 1;
  margin-right: 20px;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}

.footer .cont-news-network .news .links a:hover {
  background: #007941;
}

.footer .cont-news-network .news .links a.btn-white {
  border: 1px solid #00ba68;
  background: #ffffff;
  color: #00ba68;
}

.footer .cont-news-network .news .links a.btn-white:hover {
  color: #ffffff;
  background: #00ba68;
}

.footer .cont-news-network .sitemap-network {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding-left: 50px;
  -webkit-justify-content: space-around;
  justify-content: space-around;
}

.footer .cont-news-network .sitemap-network .sitemap > ul li:before {
  display: none;
}
@media only screen and (max-width: 860px) {

  .footer .cont-news-network .sitemap-network {
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
    padding: 0;
    margin-top: 30px;
  }

  .footer .cont-news-network .sitemap-network .sitemap {
    width: 50%;
    padding-right: 70px;
  }
}
@media only screen and (max-width: 640px) {

  .footer .cont-news-network .sitemap-network {
    -webkit-flex-direction: column;
    flex-direction: column;
  }

  .footer .cont-news-network .sitemap-network .sitemap {
    width: 100%;
    padding-right: 0;
  }
}

.footer .cont-news-network .sitemap-network .network a {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  text-decoration: none;
  color: #3c464d;
  margin-bottom: 25px;
}

.footer .cont-news-network .sitemap-network .network a:nth-of-type(1) {
  margin-top: 37px;
}

.footer .cont-news-network .sitemap-network .network a:hover {
  color: #00ba68;
}
@media only screen and (max-width: 640px) {

  .footer .cont-news-network .sitemap-network .network a {
    display: inline-block;
    margin-bottom: 0;
    text-align: center;
  }
}

.footer .cont-news-network .sitemap-network .network .icon {
  width: 41px;
  height: 41px;
  border-radius: 3px;
  background-color: #00ba68;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  margin-right: 10px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}
@media only screen and (max-width: 640px) {

  .footer .cont-news-network .sitemap-network .network .icon {
    margin: 0 26px 11px;
  }
}

.footer .cont-news-network .sitemap-network .network a.facebook-link .icon {
  background: #1778f0;
}

.footer .cont-news-network .sitemap-network .network a.facebook-link:hover .icon {
  background: #0F55AD;
}

.footer .cont-news-network .sitemap-network .network a.insta-link .icon {
  background: #df3d80;
}

.footer .cont-news-network .sitemap-network .network a.insta-link:hover .icon {
  background: #B42D65;
}

.footer .cont-news-network .sitemap-network .network a.twitter-link .icon {
  background: #4fb7f8;
}

.footer .cont-news-network .sitemap-network .network a.twitter-link:hover .icon {
  background: #3988BA;
}

.sitemap ul {
  border-top: 1px solid #9B9B9B;
  padding: 10px 0;
  margin: 0;
}

.sitemap ul li {
  margin: 15px 0;
}

.sitemap ul a {
  text-decoration: none;
  color: #3c464d;
}

.sitemap ul a:hover {
  color: #00ba68;
}

.sitemap ul:first-of-type {
  border-top: none;
}

@media only screen and (max-width: 860px) {

  .footer .logos .container {
    -webkit-flex-direction: column;
    flex-direction: column;
    text-align: center;
  }

  .footer .logos .apoyo {
    width: 100%;
    margin: 0 auto;
  }

  .footer .logos .apoyo ul {
    width: 100%;
  }

  .footer .logos .apoyo ul li {
    margin-right: 1%;
    width: 32%;
    -webkit-flex-direction: column;
    flex-direction: column;
  }

  .footer .logos .apoyo ul li p {
    font-size: .9em;
  }

  .footer .logos .apoyo ul li img {
    max-width: 100%;
  }

  .footer .map .puntos-mapacs {
    width: 100%;
  }

  .footer .map .mapacs {
    display: block;
  }
}