//Mixins

//Colores
$green-new: #00ba68;
$black-blue: #3b454e;
$yellow: #ffbf2a;
$white: #ffffff;
$black: #000000;
$gray-light: lighten(#e0e0e0, 1%);
$red: #f00;

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Roboto', sans-serif;
}

strong {
  font-weight: 700;
}

//Maintitle
.maintitle {
  color: #3f3f3f;
  font-size: 3.750em;
  font-weight: 100;
  margin: 80px 0;
  position: relative;
  text-align: center;

  &.-small {
    font-size: 2.5em;
  }

  &.-medium {
    font-size: 3em;
  }

  &.-bold {
    font-weight: 700;
  }

  .new-line {
    display: block;
  }

  @media only screen and (max-width: 640px) {
    font-size: 2.5em;
    margin: 50px 0;

    &.-small {
      font-size: 1.8em;
    }

    &.-medium {
      font-size: 2.5em;
    }
  }
}// maintitle

.-white {
  color: $white;
}

.-green {
  color: $green-new;
}

.-yellow {
  color: #ffbf2a;
}

.-centered-info {
  text-align: center;
}

.-bg-gray {
  background: #f6f6f6;
}

.-bg-green {
  background: $green-new;
}

.-bg-darkblue {
  background: $black-blue;
}

.-degradate {

  &:before {
    background-image: linear-gradient(104deg, #026d3e, #03337c 70%);
    content: "";
    height: 100%;
    left: 0;
    opacity: .67;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.button {
  transition: background-color .3s ease;
}

@media only screen and (max-width: 640px) {
  .no-mobile {
    display: none;
  }
}

@media only screen and (max-width: 860px) {
  .no-tablet {
    display: none;
  }
}

//Hero banner
.hero-banner {
  background: url('../images/hero-banner.jpg') no-repeat center;
  background-size: cover;
  margin-top: 65px;
  overflow: hidden;
  padding: 70px 0;
  position: relative;

  @media only screen and (max-width: 640px) {
    padding: 20px 0;
  }

  .maintitle {
    margin-bottom: 0;
  }

  .bajada {
    font-size: 2.2em;
    text-align: center;
    margin: 10px 0 50px;
    color: $white;
  }
}

//Explanation
.explanation {
  margin: 30px 0;
  overflow: hidden;

  .maintitle {
    margin: 50px 0 25px;

    @media only screen and (max-width: 640px) {
      margin: 20px 0;
    }
  }
}

//Beneficts boxes
.benefict-boxes {
  margin: 70px 0 0;

  $distance: 20px;
  $box-size-sm: 33.33%;
  $box-size-md: 50%;
  $box-size-xl: 100%;

  .cont-boxes {
    overflow: hidden;
  }

  .single-box {
    float: left;
    padding-bottom: $box-size-sm;
    position: relative;
    width: $box-size-sm;

    @media only screen and (max-width: 860px) {
      padding-bottom: $box-size-md;
      width: $box-size-md;
    }

    @media only screen and (max-width: 640px) {
      height: 280px;
      padding-bottom: 0;
      width: $box-size-xl;
    }
  }// single box

  .info {
    height: 100%;
    overflow: hidden;
    position: absolute;
    text-align: right;
    width: 100%;

    .sub-info {
      float: left;
      height: 100%;
      width: $box-size-sm;
    }
  }

  .info-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .full-box {
    float: left;
    padding-bottom: $box-size-sm;
    position: relative;
    width: 100%;

    @media only screen and (max-width: 860px) {
      padding-bottom: $box-size-md;

      .sub-info {
        width: 50%;
      }

      #map {
        width: 50%;
      }
    }

    @media only screen and (max-width: 640px) {
      padding-bottom: 0;

      .info {
        position: relative;
      }

      .sub-info {
        height: 280px;
        width: 100%;
      }

      #map {
        height: 280px;
        width: 100%;
      }
    }
  }

  .box-title {
    border-bottom: 1px solid;
    font-size: 1.813em;
    font-weight: normal;
    margin: 0 $distance;
    margin-bottom: 10px;
    padding-bottom: 10px; 
  }

  .box-text {
    font-size: .85em;
    line-height: 140%;
    margin: 0 $distance;
  }

  .box-image {
    min-width: 100%;
    transition: transform .5s ease;

    &:hover {
      transform: scale(1.2);

      @media only screen and (max-width: 860px) {
        transform: none;
      }
    }
  }

  #map {
    float: left;
    height: 100%;
    width: 100%;
  }
}// beneficts boxes

//Beneficts
.benefict-list {
  
  .hero {
    background: url('../images/bg-beneficts.jpg') no-repeat center;
    background-size: cover;
    color: $white;
    padding: 40px 0;
    width: 100%;
  }

  .specific-benefict {
    text-align: center;

    @media only screen and (max-width: 640px) {
      margin-bottom: 40px;
    }

    .secondarytitle {
      font-size: 1.3em;
      font-weight: 400;
      margin: 25px 0 15px;
    }

    .benefict-text {
      font-size: .85em;
      line-height: 140%;
      margin: 0;
      padding: 0 20px;
    }
  }

  .icon-beneficts {
    width: 80px;
  }
}

//Available oportunities
.available-opportunities {
  display: none;
}

//Enter curriculum
.enter-cv {

  .hero {
    background: url('../images/bg-form.jpg') no-repeat center;
    background-size: cover;
    color: $white;
    padding: 40px 0;
    width: 100%;
  }

  .cumplo-form {
    background: $white;
    margin-bottom: 0;
    padding: 35px 20px 25px;
    position: relative;
    transition: filter .45s ease;

    &.inactive .cumplo-form-field {
      filter: blur(5px);
    }
  }

  .cumplo-form-field {
    margin-bottom: .4rem;
    position: relative;
  }

  .cumplo-form_input {
    background-color: #fafbfc;
    border: 1px solid #d6e1e4;
    border-radius: .4rem;
  }

  .select {
    margin-bottom: 0;
  }

  .cumplo-form_input::placeholder {
    color: #a4b3b7;
  }

  .cumplo-form_select {
    color: #a4b3b7;
  }

  .attach-field {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .label-attach-field {
    background: url('../images/icon-upload.svg') no-repeat right center #fafbfc;
    color: #a4b3b7;
    cursor: pointer;
    font-weight: normal;
    font-family: sans-serif;
    font-size: 100%;
    height: 3.8rem;
    margin: 0;
    overflow: hidden;
    padding: .6rem 4rem .6rem 1rem;
    width: 100%;
  }

  .cumplo-form_button {
    width: 100%;
  }

  .legend {
    position: absolute;
    right: 1.75em;
    bottom: -7px;
    margin: 0;
    color: $black-blue;
    font-size: .65em;
  }

  .warning {
    display: none;
    position: absolute;
    left: 1.75em;
    bottom: -7px;
    margin: 0;
    font-size: .65em;
    color: $red;
    text-align: right;
  }

  .-visible {
    display: block;
  }

  .-hidden {
    display: none;
  }

  .post-send,
  .post-send-error {
    align-items: center;
    background: rgba(255,255,255,.6);
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    left: 0;
    opacity: 0;
    padding: 33px;
    position: absolute;
    text-align: center;
    top: 0;
    transition: opacity .3s ease, visibility .3s ease;
    visibility: hidden;
    width: 100%;

    &.active {
      opacity: 1;
      visibility: visible;
    }
  
    .icon-post {
      max-width: 130px;
      margin-bottom: 25px;
    }

    .secondarytitle {
      color: $black-blue;
    }
  }
}// enter cv